import * as Sentry from '@sentry/vue';

import { getSentryConfig } from './sentry-utils.js';

function SentryInit(app, router) {
  const config = getSentryConfig();
  if (config) {
    try {
      Sentry.init({
        app,
        ...config,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.captureConsoleIntegration({ levels: ['error'] }),
        ],
        tracesSampleRate: 0.003,
      });
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }
  }
}
export default SentryInit;
